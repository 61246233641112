<template>
  <ion-page class="page scrollable has-text">
    <TopBar />
    <div class="p-3">
      <div class="title">
        <h1>Account Settings</h1>
      </div>
      <section class="mt-2">
        <router-link :to="{ name: 'account-settings' }" class="clickable text-black">Your Account</router-link>
      </section>
      <section class="mt-2">
        <router-link v-if="emailSettingToken" :to="`/account/email/${emailSettingToken}`" class="clickable text-black"
          >Email Settings</router-link
        >
        <span v-else class="clickable text-black">Email Settings</span>
      </section>
      <section v-if="st === 's'" class="mt-2">
        <a rel="nofollow" :href="stripePortalUrl" class="link clickable text-black">Manage Subscription Info</a>
      </section>
      <section v-if="st === 'p'" class="mt-2">
        <a rel="nofollow" :href="paypalPortalUrl" class="link clickable text-black">Manage Subscription Info</a>
      </section>
      <section v-if="isSubscribed" class="mt-2">
        <span class="clickable" @click="openSubscribeModal($event, 'accountPgChangeSubscriptionLevel')">
          Change Subscription Level&nbsp;
          <div v-if="user?.subscription_level" class="d-inline-block">
            <SubPlanBadge v-if="user?.subscription_level === 1"></SubPlanBadge>
            <SubPlanBadge v-if="user?.subscription_level === 2" plan="ultra"></SubPlanBadge>
          </div>
        </span>
      </section>
      <section class="mt-2">
        <router-link :to="{ name: 'code' }" class="clickable text-black">Enter a Code</router-link>
      </section>
      <section class="mt-2">
        <router-link :to="{ name: 'user-settings' }" class="clickable text-black">Content Preferences</router-link>
      </section>
      <section class="mt-2">
        <router-link :to="{ name: 'blocked-users' }" class="clickable text-black">Blocked Users</router-link>
      </section>
      <section class="mt-2" v-if="featureFlags.storage">
        <span class="clickable text-black" @click="openStorageSpaceModal">My Storage Space</span>
      </section>

      <section class="mt-4">
        <span class="clickable text-black" @click="openReportModal">Report a problem</span>
      </section>
      <section class="mt-3">
        <span class="clickable logout" @click="startLogout">Log Out</span>
      </section>
      <section class="mt-2" v-if="!isMobile">
        <span class="gray small">Ver. 1.0{{ appVer }}</span>
      </section>
    </div>
    <ImageStorageSpaceModal :is-open="isOpenStorageSpaceModal" @close="isOpenStorageSpaceModal = false" />
    <GeneralReportModal :is-open="isOpenReportModal" @close="closeReportModal" />
  </ion-page>
</template>

<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';
import { openSubscribeModal } from '@/shared/utils/modals';
import ImageStorageSpaceModal from '@/shared/modals/ImageStorageSpaceModal.vue';
import { featureFlags } from '@/shared/config/feature-flags';
import SubPlanBadge from '@/shared/components/SubPlanBadge.vue';
import TopBar from '@/shared/components/TopBar.vue';
import GeneralReportModal from '@/shared/modals/GeneralReportModal.vue';
import { useMobile } from '~/apps/mobile/composables/useMobile';
import { confirmModifySubscriptionPaypal } from '@/shared/actions/payments';
import { toast } from '@/shared/native/toast';

const emailSettingToken = ref('');
const isOpenStorageSpaceModal = ref(false);
const paypalPortalUrl = computed(() => {
  const {
    public: { env },
  } = useRuntimeConfig();
  if (env === 'development' || env === 'staging') return 'https://www.sandbox.paypal.com/myaccount/autopay/';
  return 'https://www.paypal.com/myaccount/autopay/';
});

const isOpenReportModal = ref(false);
const emits = defineEmits(['close']);

const { isMobile } = useMobile();

const {
  public: { stripePortalUrl },
} = useRuntimeConfig();

const user = computed(() => {
  const { user } = authStore();
  return user.value;
});

const isSubscribed = computed(() => {
  // pro is an object. If it has ANY keys, user is subscribed
  return user.value?.subscription_level;
});

const st = computed(() => {
  return user.value?.st;
});

const openReportModal = () => {
  isOpenReportModal.value = true;
};
const closeReportModal = () => {
  isOpenReportModal.value = false;
  emits('close');
};

const openStorageSpaceModal = () => {
  isOpenStorageSpaceModal.value = true;
};

const startLogout = () => {
  const { logout } = authStore();
  logout();
};

const appVer = computed(() => {
  try {
    const {
      public: { buildNumber },
    } = useRuntimeConfig();
    return buildNumber;
  } catch (error) {
    return 0;
  }
});

onMounted(async () => {
  const { fetchEmailUnsubToken } = authStore();
  emailSettingToken.value = await fetchEmailUnsubToken();
  const router = useRouter();
  if (router.currentRoute.value?.query?.ssm) {
    openSubscribeModal(null, 'direct');
  }
  if (router.currentRoute.value?.query?.paypalsubchanged) {
    const sid = router.currentRoute.value?.query.subscription_id as string;
    await confirmModifySubscriptionPaypal(sid);
    toast.show('Subscription modified', 'nonative', 'primary');
  }
});
</script>
<style lang="sass" scoped>
.infinite-home
  --background: transparent
  height: calc(100% - 58px - 40px)
  overflow: auto
  &::-webkit-scrollbar
    display: none
.title
  text-align: center
  font-size: 24px

.hoverable:hover
  color: gray
  cursor: pointer
.gray
  color: gray
.page
  text-align: center
</style>
